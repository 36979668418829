@import url("@replayio/overboard/index.css");

* {
  box-sizing: border-box;
  margin: 0;
}

[aria-hidden] {
  user-select: none;
}

:root {
  --font-size-primary: 5rem;
  --font-size-secondary: 2rem;
  --font-size-tertiary: 1rem;

  --radii-primary: 0.2rem;
  --radii-secondary: 0.4rem;
  --radii-tertiary: 2rem;

  --space-1: 0.8rem;
  --space-2: 1rem;
  --space-3: 2rem;
  --space-4: 3rem;
  --space-5: 5rem;
  --space-6: 18rem;

  font-size: 60%;
}

body {
  background-color: #000;
  min-height: 100vh;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 0.025em;
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
  color: #fff;
}

img,
svg {
  display: block;
  max-width: 100%;
}

h1,
h2 {
  font-family: "Audiowide", sans-serif;
  letter-spacing: 0.05em;
}

h1 {
  font-size: var(--font-size-primary);
}

h2,
p {
  font-size: var(--font-size-secondary);
}

header {
  padding: var(--space-3) var(--space-4);
}

.screen-reader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.Column,
.Row {
  display: flex;
}

.Column {
  flex-direction: column;
}

.Row {
  flex-direction: row;
}

.ProductAnimation {
  width: 75vw;
}

@media (min-width: 768px) {
  :root {
    font-size: 80%;
  }

  .ProductAnimation {
    width: 50vw;
  }
}

@media (min-width: 1024px) {
  :root {
    font-size: 100%;
  }

  .ProductAnimation {
    width: 25vw;
  }
}

/* <Colors /> & <Color /> components */
.Colors {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.Color {
  display: grid;
}

.Color::before {
  grid-area: 1 / 1 / 1 / 1;
  position: relative;
  pointer-events: none;
  content: "";
  border: var(--overboard-radii-primary) solid #9d6bff;
  border-radius: 100%;
}

.Color input {
  grid-area: 1 / 1 / 1 / 1;
  appearance: none;
  width: var(--overboard-space-4);
  height: var(--overboard-space-4);
  min-width: 40px;
  min-height: 40px;
  border-radius: 100%;
  transform: scale(1);
  transition: transform 180ms ease-out;
  cursor: pointer;
  background-image: linear-gradient(
    135deg,
    var(--stop-1) 0%,
    var(--stop-1) 50%,
    var(--stop-2) 50%,
    var(--stop-2) 100%
  );
}

.Color input:checked {
  transform: scale(0.8);
}
